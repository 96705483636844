import { Grid } from "@mui/material";
import TitleDiv from "../../components/Common/TitleDiv";
import ActionItemStatus from "../../components/ActionItem/ActionItemStatus";
import ActionItemTable from "../../components/ActionItem/ActionItemTable";
import ActionEdit from "../../components/ActionItem/ActionEdit";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { useCallback, useEffect, useState } from "react";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../../components/Common/AlertModal";
import {
  ActionItemDetailInfo,
  ActionItemStatusInfo,
  OrganizationInfo,
} from "../../system/types/ActionItem";
import moment from "moment";
import { InitActionItemStatusInfo } from "../../system/types/initObject";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CustomButton } from "../../styles/theme";
import { AxiosResponse } from "axios";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { WorkStatus } from "../../system/types/type";
import OrganizationUnitsApi from "../../system/api/OrganizationUnitApi";
import { useRecoilState } from "recoil";
import { aiDateRangeFitler } from "../../system/atoms";
function ActionItemMain() {
  const Loading = useLoadingDispatch();

  const [actionItemList, setActionItemList] = useState<ActionItemDetailInfo[]>(
    []
  );

  const now = new Date();
  const [dateRange, setDateRange] = useRecoilState(aiDateRangeFitler);

  const [statusData, setStatusData] = useState<ActionItemStatusInfo>(
    InitActionItemStatusInfo
  );

  // 테이블 데이터
  const [tableData, setTableData] = useState<ActionItemDetailInfo[]>([]);
  const [aiStatus, setAIStatus] = useState<WorkStatus>("전체");

  const refreshStatusData = useCallback(() => {
    ActionItemsApi.getActionItemsStatus(
      moment(dateRange[0]).format("YYYY-MM-DD"),
      moment(dateRange[1]).format("YYYY-MM-DD")
    )
      .then((res) => {
        const result = res.data;
        setStatusData(result);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [dateRange]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    switch (aiStatus) {
      case "전체":
        setTableData(actionItemList); // 전체 데이터를 선택
        break;
      case "중단":
        setTableData(
          actionItemList.filter(
            (item) => item.status === "보류" || item.status === "중단"
          )
        ); // 선택된 상태에 맞는 데이터 필터링
        break;

      case "진행":
        setTableData(
          actionItemList.filter(
            (item) => item.status === "진행" && item.dueDate >= today // 진행 상태면서 오늘 날짜 이후인 데이터 필터링
          )
        );
        break;
      case "지연":
        setTableData(
          actionItemList.filter(
            (item) => item.status === "진행" && item.dueDate < today // 진행 상태면서 오늘 날짜 이후인 데이터 필터링
          )
        );
        break;
      case "등록대기":
      case "완료":
        setTableData(actionItemList.filter((item) => item.status === aiStatus)); // 선택된 상태에 맞는 데이터 필터링
        break;
      default:
        setTableData(actionItemList);
        break;
    }
  }, [aiStatus]);

  const refreshData = useCallback(() => {
    if (dateRange[0] && dateRange[1]) {
      ActionItemsApi.getActionItemsList(
        moment(dateRange[0]).format("YYYY-MM-DD"),
        moment(dateRange[1]).format("YYYY-MM-DD"),
        ""
      )
        .then((res) => {
          const transformedData = res.data.map(
            (item: ActionItemDetailInfo) => ({
              ...item,
              managerNames: item.managers
                .map((manager) => manager.manager.name)
                .join(", "),
              categoriesName: item.categories
                .map((item) => item.name)
                .join(", "),
            })
          );

          setActionItemList(transformedData);
          setTableData(transformedData);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [dateRange]);

  useEffect(() => {
    refreshData();
    refreshStatusData();
  }, [refreshData, refreshStatusData]);

  // 모달
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  function onWriteAI() {
    setOpen(true);
  }
  const onChangeDate = (args: any) => {
    if (args.value) {
      setDateRange(args.value);
    }
  };
  function downloadFile() {
    ActionItemsApi.downloadActionItem(
      moment(dateRange[0]).format("YYYY-MM-DD"),
      moment(dateRange[1]).format("YYYY-MM-DD")
    )
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }

  const [teamData, setTeamData] = useState<OrganizationInfo[]>([]);
  useEffect(() => {
    OrganizationUnitsApi.getOrganizationUnitList()
      .then((res) => {
        const updatedData = res.data.map((item: OrganizationInfo) => {
          // Apply to level 0 items
          if (item.level === 0) {
            // Apply to children of level 0 items (level 1 items)
            const updatedChildren = item.children?.map(
              (child: OrganizationInfo) => ({
                ...child,
                expanded: true,
              })
            );
            return {
              ...item,
              htmlAttributes: { class: "e-disable" },
              expanded: true,
              // Update children if they exist
              children: updatedChildren || item.children,
            };
          }
          return item;
        });
        setTeamData(updatedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <TitleDiv title="A/I(Action Item) 추진 현황"></TitleDiv>
      </Grid>
      <ActionItemStatus
        data={statusData}
        setAIStatus={setAIStatus}
        refreshData={refreshData}
      />
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        mt="10px"
        alignItems="flex-end"
      >
        <Grid item xs={6} sm={3} md={2}>
          <DateRangePickerComponent
            id="daterangepicker"
            placeholder="날짜를 선택해주세요."
            format="yyyy-MM-dd"
            locale="ko"
            delayUpdate={true}
            value={dateRange}
            onChange={onChangeDate}
          />
        </Grid>
        <Grid item>
          {aiStatus !== "전체" && (
            <CustomButton
              onClick={() => setAIStatus("전체")}
              iconCss="e-icons e-reset"
              style={{ marginRight: "5px" }}
            >
              선택 초기화
            </CustomButton>
          )}
          <CustomButton
            onClick={downloadFile}
            iconCss="e-icons e-download"
            style={{ marginRight: "5px" }}
          >
            다운로드
          </CustomButton>
          <CustomButton onClick={onWriteAI} iconCss="e-icons e-plus">
            신규 등록
          </CustomButton>
        </Grid>
      </Grid>
      <ActionEdit
        refreshData={refreshData}
        refreshStatusData={refreshStatusData}
        open={open}
        handleClose={handleClose}
        type="생성"
        teamData={teamData}
      />
      <ActionItemTable actionItemList={tableData} refreshData={refreshData} />
    </Grid>
  );
}

export default ActionItemMain;
