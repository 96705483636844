import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BaseWhite,
  ErrorDefault,
  Gray25,
  Gray300,
  Gray500,
  Gray600,
  Green500,
  SuccessDefault,
  WaitingDefault,
  WarningDefault,
} from "../../styles/Colors";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { ActionItemStatusInfo } from "../../system/types/ActionItem";
import InfoIcon from "@mui/icons-material/Info";
import { WorkStatus } from "../../system/types/type";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useRef } from "react";
interface ActionItemStatusProps {
  data: ActionItemStatusInfo;
  setAIStatus: (status: WorkStatus) => void;
  refreshData: () => void;
}

function ActionItemStatus({
  data,
  setAIStatus,
  refreshData,
}: ActionItemStatusProps) {
  const totalCnt =
    data.waitingCount +
    data.completionCount +
    data.runningCount +
    data.pendingCount +
    data.stopCount +
    data.delayCount;

  const getTitleByRole = (role: string | undefined) => {
    switch (role) {
      case "user":
        return "본인의 데이터만 조회할 수 있습니다.";
      case "admin":
        return "모든 사용자의 데이터를 조회할 수 있습니다.";
      case "executive":
        return "하위 그룹의 데이터를 조회할 수 있습니다.";
      default:
        return "권한이 없습니다.";
    }
  };

  const percentage =
    totalCnt > 0 && data?.completionCount > 0
      ? (data?.completionCount / totalCnt) * 100
      : 0;
  const formattedPercentage = Number.isInteger(percentage)
    ? percentage
    : percentage.toFixed(2);

  const progressBarRef = useRef<ProgressBarComponent>(null);
  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.refresh();
    }
  }, [percentage]);
  const onChangeStatus = (statusType: WorkStatus) => {
    setAIStatus(statusType);
  };

  const onChangeRefreshData = () => {
    refreshData();
    setAIStatus("전체");
  };

  return (
    <StatusBox container item xs={12} sx={{ marginTop: 1 }}>
      <Grid container item xs={12} sm={6} justifyContent="center">
        <Grid container item xs={12} justifyContent="space-between">
          <Grid container item xs={6} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              {totalCnt}개의 목표
            </Typography>
            <IconButton size="small" onClick={onChangeRefreshData}>
              <Tooltip title="액션아이템 목록 새로고침">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="right"
            alignItems="center"
          >
            <Typography variant="body1" color={Gray500} fontWeight="bold">
              {formattedPercentage + " "}% 달성 중
            </Typography>
            <Tooltip title={getTitleByRole(data?.role)} placement="bottom">
              <InfoIcon
                fontSize="small"
                color="action"
                sx={{ marginLeft: "3px" }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProgressBarComponent
            ref={progressBarRef}
            id="lineardeterminate"
            type="Linear"
            trackThickness={20}
            progressThickness={20}
            trackColor={BaseWhite}
            innerRadius="80%"
            progressColor={percentage ? Green500 : BaseWhite}
            cornerRadius="Round"
            value={percentage ? percentage : 0}
            animation={{
              enable: true,
              duration: 1500,
              delay: 0,
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={6}>
        <Grid container item xs={3} justifyContent="center">
          <Typography variant="body1" color={Gray600} fontWeight="bold">
            보류/중단
          </Typography>
          <Grid container item xs={12} justifyContent="center">
            {data?.stopCount ? (
              <Typography
                variant="h6"
                color={ErrorDefault}
                onClick={() => {
                  onChangeStatus("중단");
                }}
                style={{ cursor: "pointer" }}
              >
                {data?.stopCount + data?.pendingCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* <Grid container item xs={3} justifyContent="center">
          <Typography variant="body1" color={Gray600} fontWeight="bold">
            임시저장
          </Typography>
          <Grid container item xs={12} justifyContent="center">
            {data?.pendingCount ? (
              <Typography variant="h6" color={Purple500}>
                {data?.pendingCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
          </Grid>
        </Grid> */}
        <Grid container item xs={3} justifyContent="center">
          <Typography variant="body1" color={Gray600} fontWeight="bold">
            등록대기
          </Typography>
          <Grid container item xs={12} justifyContent="center">
            {data?.waitingCount ? (
              <Typography
                variant="h6"
                color={WarningDefault}
                onClick={() => {
                  onChangeStatus("등록대기");
                }}
                style={{ cursor: "pointer" }}
              >
                {data?.waitingCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={3} justifyContent="center">
          <Typography variant="body1" color={Gray600} fontWeight="bold">
            진행중 / 지연
          </Typography>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            {data?.runningCount ? (
              <Typography
                variant="h6"
                color={WaitingDefault}
                onClick={() => {
                  onChangeStatus("진행");
                }}
                style={{ cursor: "pointer" }}
              >
                {data.runningCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
            <Typography variant="body1" color={Gray600} margin="0px 5px">
              /
            </Typography>
            {data?.delayCount ? (
              <Typography
                variant="h6"
                color={WaitingDefault}
                onClick={() => {
                  onChangeStatus("지연");
                }}
                style={{ cursor: "pointer" }}
              >
                {data.delayCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={3} justifyContent="center">
          <Typography variant="body1" color={Gray600} fontWeight="bold">
            완료
          </Typography>
          <Grid container item xs={12} justifyContent="center">
            {data?.completionCount ? (
              <Typography
                variant="h6"
                color={SuccessDefault}
                onClick={() => {
                  onChangeStatus("완료");
                }}
                style={{ cursor: "pointer" }}
              >
                {data?.completionCount}
              </Typography>
            ) : (
              <Typography variant="h6" color={Gray300}>
                0
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </StatusBox>
  );
}

export default ActionItemStatus;

const StatusBox = styled(Grid)({
  backgroundColor: Gray25,
  padding: "15px 10px",
  borderRadius: "8px",
});
