import { atom } from "recoil";
import { AsmtSearch, SearchDetail } from "./types/AsmtWork";
import { InitAsmtSearch, InitSearch } from "./types/initObject";

export const searchDataState = atom<AsmtSearch>({
  key: "searchDataState",
  default: InitAsmtSearch,
});

export const searchDetailState = atom<SearchDetail>({
  key: "searchDetailState",
  default: InitSearch,
});

export const yearState = atom({
  key: "yearState",
  default: "2024",
});

export const pageState = atom({
  key: "pageState",
  default: 0,
});

export const adminCommentState = atom({
  key: "adminCommentState",
  default: 0,
});

export const adminNoteTypeState = atom({
  key: "adminNoteTypeState",
  default: true,
});

export const leftDrawerOpenState = atom({
  key: "leftDrawerOpenState",
  default: true,
});

const now = new Date();
const startDate = new Date(now.getFullYear() - 1, 0, 1);
const endDate = new Date(now.getFullYear(), 11, 31);

export const aiDateRangeFitler = atom<[Date, Date]>({
  key: "aiDateRangeFitler",
  default: [startDate, endDate],
});
